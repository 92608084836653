<template>
	<div id="upLoadFile">
		<div v-loading="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading">
			<el-alert v-if="MeetingDetail.errorMessage" title="驳回原因" type="error"
				:description="MeetingDetail.errorMessage" show-icon> </el-alert>
			<!-- <div class="fileViewBox">
				<div class="rowTitle">
					<div class="rowTitle">
						<div class="titleAndTips">
							<b>项目类型与编号</b>
						</div>
					</div>
				</div>
				<div class="rowFiles">
					<div class="row">
						<div class="rowItem">
							<span><i>*</i> 费用项目类型</span>
							<el-select size="small" clearable v-model="MeetingDetail.feeTypeNo" filterable
								placeholder="请选择费用项目类型">
								<el-option v-for="item in expenseItem" :key="item.expenseItemID"
									:label="item.expenseItem" :value="item.expenseItemID">
								</el-option>
							</el-select>
						</div>
						<div class="rowItem">
							<span>项目编号</span>
							<el-input size="small" style="width: 200px;" v-model="meetingFile.projectNo"
								placeholder="请填写金蝶项目编号" clearable prefix-icon="el-icon-edit-outline"></el-input>
						</div>

						<div class="rowItem">
							<span><i>*</i> 付款方式</span>
							<el-select size="small" clearable v-model="MeetingDetail.collectWay" placeholder="请选付款方式">
								<el-option v-for="item in CollectWay" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div> -->
			<template v-for="item in annotation">
				<div class="fileViewBox" :key="item.key" v-if="meetingFile.values&&meetingFile.values[item.key]">
					<div class="rowTitle">
						<div class="titleAndTips">
							<b>{{item.text}}</b>
							<i class="el-icon-success" v-if="meetingFile.status[item.key]=='SUCCESS'"></i>
							<template v-if="meetingFile.status[item.key]!='SUCCESS'">
								<el-link target="_blank" type="primary"
									v-if="item.key=='file6'&& meetingFile.status.file6!='SUCCESS'"
									href="//life-oasis.oss-cn-beijing.aliyuncs.com/video/%E5%8A%B3%E5%8A%A1%E5%8D%8F%E8%AE%AE%E6%98%8E%E7%BB%86.xlsx">下载劳务明细模版</el-link>
								<el-link target="_blank" type="primary"
									v-if="item.key=='file3'&& meetingFile.status.file3!='SUCCESS'"
									href="//life-oasis.oss-cn-beijing.aliyuncs.com/video/%E7%AD%BE%E5%88%B0%E8%A1%A8.xlsx">下载签到表模版</el-link>
								<span class="tip" v-if="item.key=='file7'"> 点击文件夹查看列表</span>
								<div class="errorMassage"
									v-if="(meetingFile.errorMessages[item.key]||meetingFile.fileErrorMessages[item.key])&&meetingFile.status[item.key]!='SUCCESS'">
									<i class="el-icon-warning"></i>
									<span v-if="meetingFile.fileErrorMessages[item.key]">
										{{meetingFile.fileErrorMessages[item.key]}}
									</span>
									<template v-for="(msg,i) in meetingFile.errorMessages[item.key]">
										<span :key="i" v-if="item.key!='file7'&&msg!='null'">
											{{msg}}
										</span>
									</template>
								</div>
							</template>
						</div>
						<el-upload
							v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)&&item.key=='file6'"
							class="upload" :action="`${baseUrl}/sign/live/assistant/importMeetingOrder`"
							:data="{meetingId:meetingId}" :headers="{token:token}" multiple :on-progress="onProgress"
							:on-success="File6Success" :show-file-list="false">
							<el-popover placement="left" trigger="hover" width="200">
								<el-button v-if="meetingFile.status[item.key]!='SUCCESS'" size="mini" type="primary"
									class="uploadFile" slot="reference">导入</el-button>
								<p>
									<i class="el-icon-warning" style="color: orange;"></i>
									导入操作将会覆盖并更新现有的参会人员信息
								</p>
							</el-popover>
						</el-upload>
						<el-upload
							v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)&&item.key!='file7'"
							class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
							:on-progress="onProgress" :on-success="(res,file)=>{handSuccess(res,file,item.key)}"
							:show-file-list="false">
							<el-button v-if="meetingFile.status[item.key]!='SUCCESS'" size="mini" type="primary"
								class="uploadFile">上传</el-button>
						</el-upload>
					</div>
					<div class="rowFiles" v-if="item.key!='file7'">
						<div class="row" v-for="(file,i) in meetingFile.values[item.key]" :key="i">
							<!-- {{file}} -->
							<div class="col tFileView" @click="handlePreview(file,meetingFile.fileNames[item.key][i])">
								<span class="new" v-if="meetingFile.updateMessages[item.key][i]">new</span>
								<el-image v-if="isImage(file)" :src="file" fit="cover">
									<div slot="error" class="image-slot">
										<img :src="require('@/assets/imgs/i_img.png')" />
									</div>
								</el-image>
								<el-image v-else-if="isPdf(file)" :src="require('@/assets/imgs/pdf.png')"
									fit="cover"></el-image>
								<el-image v-else-if="isPpt(file)" :src="require('@/assets/imgs/ppt.png')"
									fit="cover"></el-image>
								<el-image v-else-if="isDoc(file)" :src="require('@/assets/imgs/doc.png')"
									fit="cover"></el-image>
								<el-image v-else-if="isXls(file)" :src="require('@/assets/imgs/xls.png')"
									fit="cover"></el-image>
								<el-image v-else-if="isZip(file)" :src="require('@/assets/imgs/zip.png')"
									fit="cover"></el-image>
								<el-image v-else :src="require('@/assets/imgs/i_file.svg')" fit="cover"></el-image>
							</div>
							<div class="col tFileName" @click="handlePreview(file,meetingFile.fileNames[item.key][i])">
								{{meetingFile.fileNames[item.key][i]}}
								<el-link type="primary" @click.stop="downloadIndividualFiles(file)"
									v-if="file.includes('http://') || file.includes('https://')">下载</el-link>
							</div>
							<div class="col tOpts">
								<el-button size="mini" type="danger" @click="deleteFile(file,item.key,i)"
									v-if="meetingFile.status[item.key]!='SUCCESS'&&'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)">删除</el-button>
							</div>
						</div>
					</div>
					<div class="rowFiles" v-if="item.key=='file7'">
						<div class="users">
							<template v-for="(data,user) in meetingFile.values[item.key]"
								v-if="user.split('-')[1] == '通用文件夹'">
								<div class="nameAndOpts" :key="user" @click="personnelFile(data,user,item.key)">
									<img class="iconFile" :src="require('@/assets/imgs/i_file.svg')">
									<b class="userName">{{user.split('-')[1]}}</b>
									<span v-if="areAllArraysEmpty(data)">未上传</span>
									<span v-else-if="areAllArraysNonEmpty(data)">已上传</span>
									<span v-else>待完善</span>
								</div>
								<el-divider direction="vertical" :key="user"></el-divider>
							</template>
							<template v-for="(data,user) in meetingFile.values[item.key]"
								v-if="user.split('-')[1] == '会议照片'">
								<div class="nameAndOpts" :key="user" @click="personnelFile(data,user,item.key)">
									<img class="iconFile" :src="require('@/assets/imgs/i_file.svg')">
									<b class="userName">{{user.split('-')[1]}}</b>
									<span v-if="areAllArraysEmpty(data)">未上传</span>
									<span v-else-if="areAllArraysNonEmpty(data)">已上传</span>
									<span v-else>待完善</span>
								</div>
								<el-divider direction="vertical" :key="user"></el-divider>
							</template>
							<template v-for="(data,user) in meetingFile.values[item.key]">
								<el-popover :key="user" title="所属会议" width="auto" trigger="hover"
									:content="user.split('-')[2]?user.split('-')[2]:MeetingDetail.meetingTitle">
									<div class="nameAndOpts" :key="user" @click="personnelFile(data,user,item.key)"
										slot="reference"
										v-if="user.split('-')[1] != '通用文件夹'&&user.split('-')[1] != '会议照片'">
										<img class="iconFile" :src="require('@/assets/imgs/i_file.svg')">
										<b class="userName">{{user.split('-')[1]}} </b>
										<span v-if="areAllArraysEmpty(data)">未上传</span>
										<span v-else-if="areAllArraysNonEmpty(data)">已上传</span>
										<span v-else>待完善</span>
									</div>
								</el-popover>

							</template>
						</div>
					</div>
				</div>
			</template>

			<div class="approvalRecord" v-if="approvalRecord">
				<h3>当前会议进度</h3>
				<div class="fileList">
					{{approvalRecord}}
				</div>
			</div>

			<div class="opts">
				<el-button :loading="loading" plain type="info" @click="refreshFile()">同步文件</el-button>
				<el-button :loading="loading" plain type="primary" @click="saveMeetingFileFun()"
					v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)">保存</el-button>
				<el-button :loading="loading" plain type="success" @click="upMeetingFile()"
					v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)">提交</el-button>
			</div>
		</div>

		<!-- 个人文档上传 -->
		<el-dialog v-if="fileDialog" :visible.sync="fileDialog" :title="personnelFileData.user.split('-')[1]"
			custom-class="fileDialog" v-loading="loading" :element-loading-text="loadingText"
			element-loading-spinner="el-icon-loading">
			<div class="fileList"
				v-for="(item,key,idx) in meetingFile.values[personnelFileData.key][personnelFileData.user]" :key="key">
				<el-divider v-if="idx"></el-divider>
				<div class="fileTit">
					<div class="titAndTips">
						<b>
							<i v-if="'会议照片,劳务协议,职称证明'.includes(meetingFile.title[key])">*</i>
							{{meetingFile.title[key]}}
						</b>
						<div class="info">
							<template
								v-if="meetingFile.errorMessages[personnelFileData.key]&&meetingFile.errorMessages[personnelFileData.key][personnelFileData.user]&&meetingFile.status[key]!='SUCCESS'">
								<i class="el-icon-warning"
									v-if="meetingFile.errorMessages[personnelFileData.key][personnelFileData.user][key]"></i>
								<span
									v-for="(msg,i) in meetingFile.errorMessages[personnelFileData.key][personnelFileData.user][key]"
									:key="i">
									{{msg}}。
								</span>
							</template>
						</div>
					</div>
					<el-upload
						v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)"
						class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-progress="onProgress" :on-success="(res,file)=>{file7Success(res,file,key)} "
						:show-file-list="false">
						<el-button size="mini" type="primary" class="uploadFile">上传</el-button>
					</el-upload>
				</div>
				<template v-if="i<4 || showAll[`${personnelFileData.user}${key}`]" v-for="(file,i) in item">
					<div class="row" :key="i">
						<div class="col tFileView" @click="handlePreview(file,meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value?
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value:
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i])">
							<span class="new"
								v-if="meetingFile.updateMessages[personnelFileData.key][personnelFileData.user][key][i]">new</span>
							<el-image v-if="isImage(file)" :src="file" fit="cover">
								<div slot="error" class="image-slot">
									<img style="width: 50px;height: 50px;" :src="require('@/assets/imgs/i_img.png')" />
								</div>
							</el-image>
							<el-image v-else-if="isPdf(file)" :src="require('@/assets/imgs/pdf.png')"
								fit="cover"></el-image>
							<el-image v-else-if="isPpt(file)" :src="require('@/assets/imgs/ppt.png')"
								fit="cover"></el-image>
							<el-image v-else-if="isDoc(file)" :src="require('@/assets/imgs/doc.png')"
								fit="cover"></el-image>
							<el-image v-else-if="isXls(file)" :src="require('@/assets/imgs/xls.png')"
								fit="cover"></el-image>
							<el-image v-else-if="isZip(file)" :src="require('@/assets/imgs/zip.png')"
								fit="cover"></el-image>
							<el-image v-else :src="require('@/assets/imgs/i_file.svg')" fit="cover"></el-image>
						</div>
						<div class="col tFileName" @click="handlePreview(file),meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value?
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value:
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i]">
							{{meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value?
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i].value:
						meetingFile.fileNames[personnelFileData.key][personnelFileData.user][key][i] }}
							<el-link type="primary" @click.stop="downloadIndividualFiles(file)"
								v-if="file.includes('http://') || file.includes('https://')">下载</el-link>
						</div>
						<div class="col tOpts">
							<el-button
								v-if="'UPLOAD,ERROR,AI_REVIEW_ERROR,OFFICIAL_ERROR'.includes(MeetingDetail.meetingStatus)&&meetingFile.status.file7!='SUCCESS'"
								size="mini" type="danger"
								@click="deleteUserFile(file,personnelFileData.key,personnelFileData.user,key,i)">删除</el-button>
						</div>
					</div>
				</template>
				<div class="none" v-if="!item[0]"> <i class="el-icon-remove-outline"></i> 未上传文件</div>
				<div class="showAll">
					共 {{item.length}} 个文件
					<el-button type="text" v-if="item.length>4 && !showAll[`${personnelFileData.user}${key}`]"
						@click="showAll[`${personnelFileData.user}${key}`]=true;$forceUpdate()">展示全部</el-button>
					<el-button type="text" v-if="item.length>4 && showAll[`${personnelFileData.user}${key}`]"
						@click="showAll[`${personnelFileData.user}${key}`]=false;$forceUpdate()">折叠收起</el-button>
				</div>

			</div>

			<div class="opts">
				<el-button size="medium" type="primary" @click="fileDialog = false">确定</el-button>
			</div>
		</el-dialog>

		<!-- 文档预览 -->
		<el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" title="文档预览" custom-class="fileLog">
			<img v-if="dialogFileUrl.type == 'image'" width="100%" :src="dialogFileUrl.url">
			<div class="pdgBox" v-else-if="dialogFileUrl.type == 'pdf'">
				<pdf :src="dialogFileUrl.url" :page="currentPage" @num-pages="handleNumPages"></pdf>
				<p class="pages" v-if="numPages !== 0">总页数: {{ numPages }}</p>
				<div class="opts">
					<el-button size="mini" @click="previousPages" :disabled="currentPage === 1">上一页</el-button>
					<el-button size="mini" @click="nextPages" :disabled="currentPage === numPages">下一页</el-button>
				</div>
			</div>
			<div class="fileBox" v-else>
				<iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${dialogFileUrl.url}`"
					style="width: 100%; min-height: 100vh;"></iframe>
			</div>
		</el-dialog>
		<!-- 压缩包文件预览 -->
		<el-dialog v-if="zipVisible" :visible.sync="zipVisible" title="压缩包预览" custom-class="fileLog">
			<div class="zip">
				<!-- 文件列表 -->
				<el-table v-if="zipData" :data="zipData" style="width: 100%" row-key="name" default-expand-all
					:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column label="文件名" min-width="580">
						<template slot-scope="scope">
							<div class="fileRow" @click="handlePreview(scope.row.path)">
								<el-image v-if="scope.row.path&&isImage(scope.row.path)" :src="scope.row.path"
									fit="cover">
									<div slot="error" class="image-slot">
										<img style="width: 50px;height: 50px;"
											:src="require('@/assets/imgs/i_img.png')" />
									</div>
								</el-image>
								<el-image v-else-if="scope.row.path&&isPdf(scope.row.path)"
									:src="require('@/assets/imgs/pdf.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isPpt(scope.row.path)"
									:src="require('@/assets/imgs/ppt.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isDoc(scope.row.path)"
									:src="require('@/assets/imgs/doc.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isXls(scope.row.path)"
									:src="require('@/assets/imgs/xls.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isZip(scope.row.path)"
									:src="require('@/assets/imgs/zip.png')" fit="cover"></el-image>
								<el-image v-else :src="require('@/assets/imgs/i_file.svg')" fit="cover"></el-image>
								<span>
									{{scope.row.name}}
								</span>
							</div>
							<!-- <hr v-if="scope.row.path">
							{{scope.row.path}} -->
						</template>
					</el-table-column>
					<!-- <el-table-column prop="type" label="类型" width="180"> </el-table-column> -->
				</el-table>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		getMeetingFile,
		delMeetingFile,
		getUpdateMeetInfo,
		uploadMeetingFile,
		saveMeetingFile,
		updateMeetingFile,
		approvalProcess,
		assistantGetZipFile,
		getExpenseItem,
		getCollectWay
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	import pdf from "vue-pdf";
	export default {
		name: "upLoadFile",
		data() {
			return {
				expenseItem: [], // 金蝶费用项目列表
				meetingId: '',
				loading: false,
				loadingText: '',
				baseUrl,
				MeetingDetail: {},
				dialogVisible: false,
				zipVisible: false,
				dialogFileUrl: {},
				fileDialog: false,
				personnelFileData: {},
				meetingFile: {},
				listOfTemporaryFiles: {}, // 暂存数据中间键
				currentPage: 1, // PDF文件展示当前页码
				numPages: 0, // PDF文件展示总页码
				approvalRecord: '', // 进度明细
				token: JSON.parse(localStorage.getItem('token')).token,
				annotation: [],
				titleText: {
					file4: '会议照片',
					file5: '劳务协议'
				},
				showAll: {},
				zipData: '',
				CollectWay: []
			}
		},
		created() {
			this.meetingId = this.$route.query.mId;
			this.getExpenseItemFun();
			this.getCollectWayFun();
		},
		mounted() {
			this.getMeetInfo();
			this.getMeetFile();
		},
		methods: {
			getExpenseItemFun(itemName) {
				let data = {}
				data.current = 1;
				data.size = 3000;
				if (itemName) data.itemName = itemName
				getExpenseItem(data).then(res => {
					if (res.code == 200) {
						this.expenseItem = res.data.data
					}
				})

			},

			getCollectWayFun() {
				getCollectWay({
					meetingId: this.meetingId,
					type: "meeting"
				}).then(res => {
					if (res.code == 200) {
						this.CollectWay = res.data;
					}
				})
			},

			// 下载单个文件
			downloadIndividualFiles(file) {
				// 判断 file 中是否包含  http://  或包含https://
				if (file.includes('http://') || file.includes('https://')) {
					// 如果包含，直接使用 file.url
					window.open(file);
				}
			},

			// 判断一个对象中的所有数组是否都为空
			areAllArraysEmpty(obj) {
				return Object.values(obj).every(function(value) {
					return Array.isArray(value) && value.length === 0;
				});
			},
			// 判断一个对象中的所有数组是否都有值
			areAllArraysNonEmpty(obj) {
				return Object.values(obj).every(Array.isArray) &&
					Object.values(obj).every(arr => arr.some(item => item));
			},
			// 文件上传中
			onProgress(event, file, fileList) {
				this.loading = true;
				if (event.percent < 100) {
					this.loadingText = `文件上传中···，当前${event.percent.toFixed(2)}%`;
				} else {
					this.loadingText = '文件上传成功，服务器正在处理···';
				}

				// setTimeout(() => {
				// 	this.loadingText = '文件上传中···，当前上传的文件较大，请耐心等待。';
				// }, 1000)

				console.log('文件上传中')
				console.log(event.percent)
				// console.log(file)
				// console.log(fileList)
			},
			// 人员文件上传
			personnelFile(data, user, key) {
				// console.log(data, user, key)
				this.personnelFileData = {
					user,
					key
				};
				this.fileDialog = true;
			},
			// 检查数据类型是数组 还是对象
			isArray(item) {
				if (Array.isArray(item)) {
					return true
				} else {
					return false
				}
			},
			//  通过文件链接下载文件
			downLoad(file, idx, key) {
				let url = file.url;
				let name = file.name;

				let a = document.createElement('a');
				let clickEvent = new MouseEvent('click');
				a.href = url;
				a.download = name;
				a.dispatchEvent(clickEvent);
				a.remove();
			},
			// 删除上传的内容
			deleteFile(file, key, idx) {
				//删除前确认
				this.$confirm(`此操作将永久删除该文件, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (file.includes("http://") || file.includes("https://")) {
						// console.log('删除系统存档文件')
						// console.log(file, key, idx)						// console.log(id)
						let id = this.meetingFile.fileNames[key][idx].split("-")[0]
						delMeetingFile({
							// filePath: file
							id: id
						}).then(res => {
							if (res.code == 200) {
								this.meetingFile.values[key].splice(idx, 1);
								this.meetingFile.fileNames[key].splice(idx, 1);
							} else {
								this.$message.error(res.msg);
							}
						})
					} else {
						console.log('删除页面展示文件，文件夹外文件')
						this.meetingFile.values[key].splice(idx, 1);
						this.meetingFile.fileNames[key].splice(idx, 1);
					}

				})
			},
			deleteUserFile(file, key, user, row, idx) {
				let id = user.split("-")[0]
				//删除前确认
				this.$confirm(`此操作将永久删除该文件, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (file.includes("http://") || file.includes("https://")) {
						console.log('删除系统存档文件')
						delMeetingFile({
							// filePath: file
							id: id
						}).then(res => {
							if (res.code == 200) {
								console.log(res.data);
								this.meetingFile.values[key][user][row].splice(idx, 1);
							} else {
								this.$message.error(res.msg);
							}
						})
					} else {
						// console.log('删除页面展示文件，文件夹内文件')
						// console.log(key)
						// console.log(user)
						// console.log(row)
						// console.log(idx)
						// console.log(this.meetingFile.values[key][user][row]);
						// console.log(this.meetingFile.values[key][user][row][idx]);
						// // 删除
						// this.meetingFile.values[key][user][row].splice(idx, 1);

						this.meetingFile.values[key][user][row].splice(idx, 1);
						this.meetingFile.fileNames[key][user][row].splice(idx, 1);
					}
				})
			},
			// 保存文件
			saveMeetingFileFun() {
				this.listOfTemporaryFiles.file7 = JSON.parse(JSON.stringify(this.meetingFile.fileNames.file7));
				let data = {
					files: JSON.parse(JSON.stringify(this.meetingFile.values)),
					applyMoney: this.MeetingDetail.applyMoney ? this.MeetingDetail.applyMoney : 1,
					names: JSON.parse(JSON.stringify(this.listOfTemporaryFiles)),
					meetingId: this.meetingId,
					annotation: this.annotation,
					projectNo: this.meetingFile.projectNo,
					feeTypeNo: this.MeetingDetail.feeTypeNo,
					collectWay: this.MeetingDetail.collectWay
				};
				// console.log(JSON.parse(JSON.stringify(data)));
				if (data.names.file7) {
					for (let k in data.names.file7) {
						let item = data.names.file7[k];
						// console.log("key1", k, item);
						for (let k2 in item) {
							let item2 = item[k2];
							// console.log("key2", k2, item2);
							item2.map((item3, k3) => {
								// console.log("key3", k3, item3);
								if (!data.names[k2]) data.names[k2] = [];
								if (!data.files[k2]) data.files[k2] = [];
								// console.log("files", data.files)
								// console.log("files[k2]", data.files[k2])
								if (item3) {
									data.names[k2].push({
										value: item3.value ? item3.value : item3,
										key: data.files.file7[k][k2][k3],
										user: k
									});
									data.files[k2].push(data.files.file7[k][k2][k3]);
								};
							});
						};
					};
					delete data.names.file7;
					delete data.files.file7;
				};

				this.loading = true;
				this.loadingText = "正在保存···"
				saveMeetingFile(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.getMeetFile();
						this.$notify({
							title: '保存成功',
							type: 'success',
							message: '保存成功',
							position: 'bottom-right'
						});
					} else {
						this.$notify({
							title: '保存失败',
							type: 'error',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				}).catch(err => {
					this.loading = false;
				})
			},
			// 提交文件
			upMeetingFile() {
				this.listOfTemporaryFiles.file7 = JSON.parse(JSON.stringify(this.meetingFile.fileNames.file7));
				let data = {
					files: JSON.parse(JSON.stringify(this.meetingFile.values)),
					applyMoney: this.MeetingDetail.applyMoney ? this.MeetingDetail.applyMoney : 1,
					names: JSON.parse(JSON.stringify(this.listOfTemporaryFiles)),
					meetingId: this.meetingId,
					annotation: this.annotation,
					projectNo: this.meetingFile.projectNo,
					feeTypeNo: this.MeetingDetail.feeTypeNo,
					collectWay: this.MeetingDetail.collectWay
				};
				if (data.names.file7) {
					for (let k in data.names.file7) {
						let item = data.names.file7[k];
						for (let k2 in item) {
							let item2 = item[k2];
							item2.map((item3, k3) => {
								if (!data.names[k2]) data.names[k2] = [];
								if (!data.files[k2]) data.files[k2] = [];
								if (item3) {
									data.names[k2].push({
										value: item3.value ? item3.value : item3,
										key: data.files.file7[k][k2][k3],
										user: k
									});
									data.files[k2].push(data.files.file7[k][k2][k3]);
								};
							});
						};
					};
					delete data.names.file7;
					delete data.files.file7;
				};

				// console.log(JSON.parse(JSON.stringify(data)));
				// return false;

				// if (!data.projectNo) {
				// 	this.$alert('请填写金蝶项目编号。', '提示', {
				// 		confirmButtonText: '确定',
				// 	});
				// } else 
				// if (!data.feeTypeNo || data.feeTypeNo == null) {
				// 	this.$alert('请选择金蝶费用项目类型', '提示', {
				// 		confirmButtonText: '确定',
				// 	});
				// } else if (!data.collectWay) {
				// 	this.$alert('请选择付款方式', '提示', {
				// 		confirmButtonText: '确定',
				// 	});
				// } else 
				if (!data.files.file1[0]) {
					this.$alert('会议通知未上传', '提示', {
						confirmButtonText: '确定',
					});
				} else if (!data.files.file2[0]) {
					this.$alert('会议日程未上传', '提示', {
						confirmButtonText: '确定',
					});
				} else {
					console.log(JSON.parse(JSON.stringify(data)));
					// return false;
					this.loading = true;
					this.loadingText = "正在提交···"
					uploadMeetingFile(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							this.$router.push({
								name: 'meetdetails',
								query: {
									mId: this.meetingId,
									now: 3
								}
							})
							this.$notify({
								title: '上传成功',
								type: 'success',
								message: '上传成功',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '失败',
								type: 'error',
								message: res.msg,
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						this.loading = false;
					})
				}
			},
			// 刷新文件列表
			refreshFile() {
				this.loading = true;
				this.loadingText == "正在刷新···"
				updateMeetingFile({
					meetingId: this.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.getMeetFile();
						this.loading = false;
					} else {
						this.loading = false;
					}
				}).catch(() => {
					this.loading = false;
				})
			},
			handSuccess(res, file, key) {
				this.loading = false;
				this.meetingFile.values[key].push(res);
				if (!this.meetingFile.updateMessages[key]) {
					this.meetingFile.updateMessages[key] = [];
				}
				if (this.meetingFile.fileNames[key]) {
					this.meetingFile.fileNames[key].push(file.name);
				} else {
					this.meetingFile.fileNames[key] = [file.name];
				}
				if (this.listOfTemporaryFiles[key]) {
					this.listOfTemporaryFiles[key].push({
						key: res,
						value: file.name
					})
				} else {
					this.listOfTemporaryFiles[key] = [{
						key: res,
						value: file.name
					}]
				}
				this.$forceUpdate();
				this.loading = false;
				// console.log(JSON.parse(JSON.stringify(this.meetingFile)))
			},
			file7Success(res, file, key) {
				// console.log(JSON.parse(JSON.stringify(this.listOfTemporaryFiles)));
				this.meetingFile.values[this.personnelFileData.key][this.personnelFileData.user][key].push(res);
				this.meetingFile.fileNames[this.personnelFileData.key][this.personnelFileData.user][key].push({
					key: res,
					value: file.name
				});
				this.listOfTemporaryFiles.file7 = this.meetingFile.fileNames.file7;

				this.$forceUpdate();
				this.loading = false;
				// console.log(JSON.parse(JSON.stringify(this.meetingFile)))
			},
			File6Success(res, file) {
				// console.log(res)
				// console.log(file.name)
				if (res.code == 200) {
					file.response = res.data.fileName;
					this.meetingFile.values.file6 = [res.data.fileName];
					// if (this.meetingFile.fileNames.file6) {
					// 	this.meetingFile.fileNames.file6.push(file.name);
					// } else {
					this.meetingFile.fileNames.file6 = [file.name];
					// }
					// if (this.listOfTemporaryFiles.file6) {
					// 	this.listOfTemporaryFiles.file6.push({
					// 		key: res.data.fileName,
					// 		value: file.name
					// 	})
					// } else {
					this.listOfTemporaryFiles.file6 = [{
						key: res.data.fileName,
						value: file.name
					}]
					// }
					this.meetingFile.fileNames.file7 = res.data.fileNames;
					this.meetingFile.values.file7 = res.data.values;
					this.$message({
						message: `文件 ${file.name} 上传成功。${res.data.msg}`,
						type: 'success'
					});
					this.$alert(`文件 ${file.name} 上传成功。${res.data.msg}`, '提示', {
						confirmButtonText: '确定',
						type: 'success',
						center: true
					});
				} else {
					this.$message({
						message: `${res.msg}`,
						type: 'error'
					});
					this.$alert(`${res.msg}`, '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						center: true
					});
				}
				this.loading = false;
			},
			// 数组去除空值
			ClearNullArr(arr) {
				for (var i = 0, len = arr.length; i < len; i++) {
					if (!arr[i] || arr[i] == '' || arr[i] == 'null' || arr[i] === undefined) {
						arr.splice(i, 1);
						len--;
						i--;
					}
				}
				return arr;
			},
			// 会后上传文件回显
			getMeetFile() {
				getMeetingFile({
					meetingId: this.meetingId
				}).then(res => {
					let data = JSON.parse(JSON.stringify(res.data));
					for (let key in data.errorMessages) {
						let msg = data.errorMessages[key];
						if (key == "file7") {
							for (let key2 in msg) {
								let msg2 = msg[key2];
								for (let key3 in msg2) {
									let msg3 = msg2[key3];
									if (msg3) {
										data.errorMessages[key][key2][key3] = this.ClearNullArr(msg3.split(
											'\n'));
									}
								}
							}
						} else {
							if (msg) {
								data.errorMessages[key] = this.ClearNullArr(msg.split('\n'));
							};
						}
					}

					// this.listOfTemporaryFiles = JSON.parse(JSON.stringify(data.fileNames));
					this.annotation = data.annotation;
					this.meetingFile = data;
				})
			},
			// 获取会议信息
			getMeetInfo() {
				let data = {
					meetingId: this.meetingId
				}
				getUpdateMeetInfo(data).then(res => {
					if (res.code == 200) {
						this.MeetingDetail = res.data
					}
				})
				approvalProcess(data).then(res => {
					if (res.code == 200) {
						this.approvalRecord = res.data
					}
				})
			},
			isImage(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
				return imageExtensions.includes(extension);
			},
			isFile(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
				return imageExtensions.includes(extension);
			},
			isDoc(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['doc', 'docx'];
				return imageExtensions.includes(extension);
			},
			isXls(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['xls', 'xlsx'];
				return imageExtensions.includes(extension);
			},
			isZip(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['zip', 'rar', '7z', 'tar'];
				return imageExtensions.includes(extension);
			},
			isPpt(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['ppt', 'pptx'];
				return imageExtensions.includes(extension);
			},
			isPdf(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['pdf'];
				return imageExtensions.includes(extension);
			},
			getZipFileFun(id, name) {
				let zipId = name.split('-')[0];
				// console.log(zipId)
				assistantGetZipFile({
					zipId: zipId
				}).then(res => {
					if (res.code == 200) {
						// console.log(res.data)
						this.zipData = res.data.children
					}
				})
			},
			handlePreview(file, name) {
				if (file) {
					if (file.includes("http://") || file.includes("https://")) {
						this.dialogFileUrl = {
							url: file,
						};
						if (this.isImage(file)) {
							this.dialogFileUrl.type = 'image';
							this.dialogVisible = true;
						} else if (this.isZip(file)) {
							this.dialogFileUrl.type = 'zip';
							this.getZipFileFun(file, name);
							this.zipVisible = true;
						} else if (this.isFile(file)) {
							this.dialogFileUrl.type = 'file';
							this.dialogVisible = true;
						} else {
							this.dialogFileUrl.type = 'pdf';
							this.dialogVisible = true;
						}
					} else {
						this.$message.error(`文件未同步到服务器，请先保存或提交。`);
					}
				}
			},
			handleExceed(files, fileList) {
				this.$message.warning(`本次选择了 ${files.length} 个文件，文件数量超限`);
			},
			// 获取页码总数
			handleNumPages(numPages) {
				this.numPages = numPages;
			},
			// 点击之后页面切换为上一页
			previousPages() {
				this.currentPage--;
			},
			// 点击之后页面切换为上一页
			nextPages() {
				this.currentPage++;
			},
		},
		components: {
			pdf,
		},
	}
</script>

<style lang="scss" scoped>
	#upLoadFile {
		padding: 30px;

		.el-alert {
			margin-bottom: 20px;
		}

		.approvalRecord {
			margin-bottom: 20px;
			width: 100%;
			padding: 10px 20px;
			border-radius: 10px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

			h3 {
				font-size: 18px;
				color: #112950;
			}
		}

		.fileViewBox {
			padding: 20px;
			border-radius: 10px;
			margin-bottom: 10px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

			.rowTitle {
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.titleAndTips {
					flex: 1;
					display: flex;
					margin-right: 20px;
					align-items: center;

					.el-link {
						font-size: 12px;
						margin-left: 10px;
					}

					.tip {
						font-weight: normal;
						font-size: 12px;
						color: #999;
						margin-left: 10px;
					}

					.el-icon-warning {
						font-size: 14px;
						color: #F56C6C;
					}

					.el-icon-success {
						font-size: 14px;
						margin-left: 10px;
						color: #67C23A;
					}

					.errorMassage {
						font-size: 14px;
						margin-left: 10px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						span {
							margin-left: 5px;
							font-size: 12px;
							color: #f56c6c;
						}
					}
				}


				b {
					font-size: 16px;
					color: #666;
				}

				::v-deep .uploadFile {
					margin-right: 5px;
					width: 56px;
					height: 28px;
				}
			}

			.rowFiles {
				width: 100%;

				.row {
					display: flex;
					align-items: flex-end;
					margin-bottom: 10px;
					padding: 5px;
					border-radius: 10px;
					transition: all .3s;

					&:hover {
						background-color: #f6f8f9;

						.tFileName {
							.el-link {
								display: block;
							}
						}
					}

					.rowItem {
						margin-right: 20px;
						padding: 10px 0;

						span {
							margin-right: 10px;
							font-size: 14px;

							i {
								font-style: normal;
								color: #f56c6c;
							}
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					.tFileView {
						margin-right: 20px;
						cursor: pointer;
						display: flex;
						position: relative;

						>.new {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 100;
							line-height: 1;
							padding: 3px;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #f56c6c;
							color: #fff;
							border-radius: 5px;
							font-size: 12px;
							font-weight: bold;
						}

						::v-deep * {
							cursor: pointer;
						}

						::v-deep .el-image {
							width: 50px;
							height: 50px;
							border-radius: 10px;
							overflow: hidden;

							.image-slot {
								width: 50px;
								height: 50px;

								img {
									display: block;
									width: 50px;
									height: 50px;
									object-fit: cover;
								}
							}
						}
					}

					.tFileName {
						flex: 1;
						margin-right: 20px;
						cursor: pointer;
						display: flex;
						align-items: center;

						.el-link {
							margin-left: 20px;
							font-size: 12px;
							display: none;
						}
					}

					.tOpts {
						min-width: 100px;
						display: flex;
						align-items: center;
						justify-content: flex-end;

					}
				}

				.users {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.nameAndOpts {
						display: flex;
						flex-direction: column;
						align-items: center;
						cursor: pointer;
						margin: 5px;
						// padding-bottom: 5px;
						border-radius: 10px;
						margin-bottom: 0;
						position: relative;


						* {
							cursor: pointer;
						}

						&:hover {
							background-color: #f6f8f9;
						}

						span {
							position: absolute;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							font-size: 14px;
							font-weight: bold;
							text-shadow: 0 0 10px rgba(0, 0, 0, 0.31);
							border-radius: 10px;
							opacity: 0.5;
						}


						.iconFile {
							width: 100px;
							height: 80px;
							border-radius: 5px;
							overflow: hidden;
						}

						.userName {
							font-size: 14px;
							color: #666;

						}
					}


					.el-divider {
						height: 60px;
					}

					// 	.userRow {
					// 		margin-bottom: 10px;
					// 		padding-left: 10px;

					// 		&:last-child {
					// 			margin-bottom: 0;
					// 		}

					// 		.userFileTit {
					// 			display: block;
					// 			color: #666;
					// 			margin-bottom: 10px;
					// 		}
					// 	}

				}
			}
		}

		.opts {
			display: flex;
			align-items: center;
			justify-content: center;

			.el-button {
				width: 140px;
			}
		}

		.fileList {
			.fileRow {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				transition: all .3s;
				margin-bottom: 10px;

				b {
					font-size: 14px;
					font-weight: normal;
					cursor: pointer;
					display: flex;
					align-items: center;
					margin-left: 10px;

					i {
						font-size: 16px;
						margin-right: 4px;
					}

					.el-icon-picture {
						color: blueviolet;
					}

					.el-icon-files {
						color: darkorange;
					}
				}

				.del {
					display: none;
					cursor: pointer;
					margin-left: 10px;

					i {
						font-size: 16px;
					}
				}

				.doLod {
					display: none;
					cursor: pointer;
					margin-left: 10px;

					i {
						font-size: 16px;
					}
				}

				.fileType {
					display: block;
					color: green;
					margin-left: 10px;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:hover {
					background-color: #f5f5f5;

					.del {
						display: block;
						color: red;
					}

					.doLod {
						display: block;
						color: #0065FF;
					}

					.fileType {
						display: none;
					}
				}
			}
		}
	}

	::v-deep .fileLog {
		margin: 0 !important;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 90vw;
		max-width: 1200px;
		max-height: 95vh;
		overflow-y: auto;

		.el-dialog__body {
			padding-top: 0;
		}

		.pdgBox {
			.pages {
				text-align: center;
			}

			.opts {
				display: flex;
				align-items: center;
				justify-content: space-around;
			}
		}

		.cell {
			display: flex;
			align-items: center;
		}

		.fileRow {
			display: inline-flex;
			align-items: center;

			.el-image {
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}
		}
	}

	::v-deep .fileDialog {
		margin: 0 !important;
		position: fixed;
		max-height: 95vh;
		overflow-y: auto;
		max-width: 1200px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.el-dialog__body {
			padding-top: 0;
		}

		.fileList {
			margin-bottom: 10px;

			:last-child {
				margin: 0;
			}

			.row {
				display: flex;
				align-items: center;
				// margin-bottom: 5px;
				padding: 5px;
				border-radius: 10px;
				transition: all .3s;

				&:hover {
					background-color: #f6f8f9;

					.tFileName {
						.el-link {
							display: block;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				.tFileView {
					margin-right: 20px;
					cursor: pointer;
					display: flex;
					position: relative;

					>.new {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 100;
						line-height: 1;
						padding: 3px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #f56c6c;
						color: #fff;
						border-radius: 5px;
						font-size: 12px;
						font-weight: bold;
					}

					::v-deep * {
						cursor: pointer;
					}

					.el-image {
						width: 50px;
						height: 50px;
						border-radius: 10px;
						overflow: hidden;
					}
				}

				.tFileName {
					flex: 1;
					margin-right: 20px;
					cursor: pointer;
					display: flex;
					align-items: center;

					.el-link {
						margin-left: 20px;
						font-size: 12px;
						display: none;
					}
				}

				.tOpts {
					min-width: 100px;
					display: flex;
					align-items: center;
					justify-content: flex-end;

				}
			}

			.none {
				font-size: 12px;
				color: #999;
				border: 1px solid #eee;
				padding: 5px 10px;
				border-radius: 5px;
				display: inline
			}
		}

		.fileTit {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 10px;
			color: #666;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			.titAndTips {
				flex: 1;
				margin-right: 20px;
				display: flex;
				align-items: flex-start;

				i {
					font-size: 14px;
					font-style: normal;
					color: #f56c6c;
				}

				.info {
					margin-left: 10px;
					flex: 1;
					text-align: justify;
				}

				span {
					font-size: 12px;
					margin-left: 5px;
					color: #f56c6c;
				}
			}

			.uploadFile {
				width: 56px;
				margin-right: 5px;
			}
		}

		.showAll {
			text-align: center;
		}
	}
</style>